import React from "react";



function UserDetails({setShoeUser,user}){

 return (
<div className="modalBackground">
    <div className="modalContainer">
        <div  className="titleCloseBtn bg-dark">
        <button
           onClick={() => {
              setShoeUser(false)
             }}
         >
           X
         </button>

        </div>
        <div className="userDetails">
        <div className="row">
            <div className="col-sm-6">
                <p>Mobile No:</p>
                <p>Name:</p>
                <p>Service:</p>
                <p>Call Time:</p>
                <p>Submit Time:</p>
                {user.age && <p>Age:</p>}
                {user.visitDate && <p>Visit Date:</p>}
                {user.visitTime && <p>Visit Time:</p>}
                {user.fileURL && <p>Attachment:</p>}
                {user.travelDateFrom && <p>Date From:</p>}
                {user.travelDateTo && <p>Date To:</p>}
                {user.travelPlaceFrom && <p>Place From:</p>}
                {user.travelPlaceTo && <p>Place To:</p>}
                {user.Adult && <p>Adult:</p>}
                {user.child && <p>child:</p>}
                {user.address && <p>Address:</p>}
                {user.otherDescription && <p>Other Description:</p>}





                 </div>
            <div className="col-sm-6">
            <p>{user.mobileNo}</p>
            <p>{user.name}</p>
           <p>{user.service}</p>
           <p>{user.callTime}</p>
           <p>{user.submitTime}</p>
           {user.age && <p>{user.age}</p>}
           {user.visitDate && <p>{user.visitDate}</p>}
                {user.visitTime && <p>{user.visitTime}</p>}
                {user.fileURL && <p><a href={user.fileURL}><img src="https://img.icons8.com/color/20/000000/download--v1.png"/></a></p>}
                {user.travelDateFrom && <p>{user.travelDateFrom}</p>}
                {user.travelDateTo && <p>{user.travelDateTo}</p>}
                {user.travelPlaceFrom && <p>{user.travelPlaceFrom}</p>}
                {user.travelPlaceTo && <p>{user.travelPlaceTo}</p>}
                {user.Adult && <p>{user.Adult}</p>}
                {user.child && <p>{user.child}</p>}
                {user.address && <p>{user.address}</p>}
                {user.otherDescription && <p>{user.otherDescription}</p>}
            </div>
           
        </div>
        </div>
        
    </div>
</div>
 )

}


export default UserDetails;