const items = [{
    name: "Home",
    icon: "https://img.icons8.com/nolan/64/A3A1FF/3A3897/home-page.png",
    id: "#Home Services"
},
{
    name: "Construction",
    icon: "https://img.icons8.com/nolan/64/A3A1FF/3A3897/workers-male.png",
    id:   "#Construction"
},
{
    name: "Repair",
    icon: "https://img.icons8.com/nolan/64/A3A1FF/3A3897/maintenance.png",
    id:   "#Appliance services and repair"
},
{
    name: "Legal Consultancy",
    icon: "https://img.icons8.com/nolan/64/A3A1FF/3A3897/scales.png",
    id:   "#Legal Consultancy"
},
{
    name: "Photography",
    icon: "https://img.icons8.com/nolan/64/A3A1FF/3A3897/compact-camera.png",
    id:   "#Photography and videography"
},
{
    name: "Events",
    icon: "https://img.icons8.com/nolan/64/A3A1FF/3A3897/event-accepted.png",
    id:   "#Events Management and caterers"
},
{
    name: "Architecture",
    icon: "https://img.icons8.com/nolan/64/A3A1FF/3A3897/parliament.png",
    id:   "#Architecture"
},
{
    name: "Printing",
    icon: "https://img.icons8.com/nolan/64/A3A1FF/3A3897/print.png",
    id:   "#Printing"
},
{
    name: "Web Development",
    icon: "https://img.icons8.com/nolan/64/A3A1FF/3A3897/backend-development.png",
    id:   "#Web Development"
},
{
    name: "Solar System",
    icon: "https://img.icons8.com/nolan/64/A3A1FF/3A3897/solar-energy.png",
    id:   "#Solar System"
},
{
    name: "Physiothearapy And Accupressure",
    icon: "https://img.icons8.com/nolan/64/A3A1FF/3A3897/medical-doctor.png",
    id:   "#Physiothearapy And Accupressure"
},
{
    name: "Beautician And Mehndi",
    icon: "https://img.icons8.com/nolan/64/A3A1FF/3A3897/barbershop.png",
    id:   "#Beautician And Mehndi"
},

{
    name: "Car/Taxi Booking On Rent",
    icon: "https://img.icons8.com/nolan/64/A3A1FF/3A3897/hover-car.png",
    id:    "#Car/Taxi Booking On Rent"

},

    {
    name: "Medical Testing",
    icon: "https://img.icons8.com/nolan/64/A3A1FF/3A3897/syringe.png",
    id:   "#Medical Testing"
}];

export default items;