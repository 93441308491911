import React from "react";




function Credit(){

   return(
    <>
    <a href="https://www.freepik.com/vectors/water-heater">Water heater vector created by upklyak - www.freepik.com</a>         <br/>
    <a href="https://www.freepik.com/photos/wifi-signal">Wifi signal photo created by freepik - www.freepik.com</a><br/>
    <a href="https://www.freepik.com/photos/ceiling-fan">Ceiling fan photo created by lifeforstock - www.freepik.com</a><br/>
    <a href="https://www.freepik.com/photos/foot-care">Foot care photo created by jcomp - www.freepik.com</a><br/>
    <a href="https://www.freepik.com/photos/water-dispenser">Water dispenser photo created by DCStudio - www.freepik.com</a><br/>
    <a href="https://www.freepik.com/photos/stay-home">Stay home photo created by freepik - www.freepik.com</a><br/>
    <a target="_blank" href="https://icons8.com/icon/123742/home-page">Home Page icon by Icons8</a><br/>
    <a target="_blank" href="https://icons8.com/icon/znmq1N3OmR7k/workers">Workers icon by Icons8</a><br/>
    <a target="_blank" href="https://icons8.com/icon/43630/tools">Tools icon by Icons8</a><br/>
    <a target="_blank" href="https://icons8.com/icon/44840/scales">Scales icon by Icons8</a><br/>
    <a target="_blank" href="https://icons8.com/icon/43604/compact-camera">Compact Camera icon by Icons8</a><br/>
    <a target="_blank" href="https://icons8.com/icon/52971/event-accepted">Event Accepted icon by Icons8</a><br/>
    <a target="_blank" href="https://icons8.com/icon/52993/parliament">Parliament icon by Icons8</a><br/>
    <a target="_blank" href="https://icons8.com/icon/44040/print">Print icon by Icons8</a><br/>
    <a target="_blank" href="https://icons8.com/icon/WMvhDPZBJ9X2/backend-development">Backend Development icon by Icons8</a><br/>
    <a target="_blank" href="https://icons8.com/icon/X6lyy4fcBpgL/solar-energy">Solar Energy icon by Icons8</a><br/>
    <a target="_blank" href="https://icons8.com/icon/44821/medical-doctor">Medical Doctor icon by Icons8</a><br/>
    <a target="_blank" href="https://icons8.com/icon/0K5b6A1LtLhd/barbershop">Barbershop icon by Icons8</a><br/>
    <a target="_blank" href="https://icons8.com/icon/Sc8xDbvZrlSW/hover-car">Hover Car icon by Icons8</a><br/>
    <a target="_blank" href="https://icons8.com/icon/52583/syringe">Syringe icon by Icons8</a><br/>
    <a href="https://www.freepik.com/photos/construction-materials">Construction materials photo created by diana.grytsku - www.freepik.com</a>
    
    </>

   )

}


export default Credit;